import React, { Component } from 'react'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

class Pricing extends Component {
  state = {
    categories: ['Ally for Teams', 'Ally for Enterprise']
  }

  render() {
    const { categories } = this.state

    return (
      <Tabs className="pricing">
        <TabList>
          {categories.map((item, i) => (
            <Tab key={i}>{item}</Tab>
          ))}
        </TabList>

        <TabPanel>
          <div className="pricing__item">
            <h2 className="pricing__heading heading heading--md">
              Ally For Teams
            </h2>
            <p className="pricing__subheading">
              Ally for Teams is a single place for your team visibility.
            </p>
            <div className="pricing__card">
              <h2 className="heading heading--md">Standard</h2>
              <p className="small bold">
                29 USD <br /> for teams of up to 10 people
              </p>
              <p className="extra-small">
                5 USD <br /> per active user, per month
              </p>
              <a href="/" className="pricing__btn btn btn--blue">
                Buy Standard
              </a>
              <ul className="pricing__list">
                <li>Unlimited integrations</li>
                <li>Unlimited dashboards</li>
                <li>Unlimited alerts and notifications</li>
                <li>Unlimited scheduled reports</li>
                <li>Advanced permissions</li>
                <li>Priority support</li>
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="pricing__item">
            <h2 className="pricing__heading heading heading--md">
              Ally For Enterpise{' '}
            </h2>
            <p className="pricing__subheading">
              Ally for Teams is a single place for your team visibility.
            </p>
            <div className="pricing__card">
              <h2 className="heading heading--md">Enterprise</h2>
              <p className="small bold">Starting from 999 USD</p>
              <p className="extra-small">
                Ally powers the usage and administration of multiple teams with
                On-Premises integrations
              </p>
              <a
                href="/"
                className="pricing__btn pricing__btn--top btn btn--blue"
              >
                Contact Us
              </a>
              <ul className="pricing__list">
                <li>Unlimited integrations</li>
                <li>Unlimited dashboards</li>
                <li>Unlimited alerts and notifications</li>
                <li>Unlimited scheduled reports</li>
                <li>Advanced permissions</li>
                <li>Priority support</li>
                <li>Account Manager</li>
              </ul>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    )
  }
}

export default Pricing
